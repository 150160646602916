const Seo = {
  install(Vue, options) {
    Vue.mixin({
      metaInfo() {
        const defaultDescription = `${this.$whitelabel.branding.seoDefaultDescription}`;
        if (!this.$route || !this.$route.meta || !this.$route.meta.seoTitle) {
          return;
        }
        return {
          title: this.$route.meta.seoTitle,
          titleTemplate: `%s | ${this.$whitelabel.branding.productName}`,
          meta: [
            {
              property: "og:title",
              content:
                `${this.$whitelabel.branding.productName} - ` +
                this.$route.meta.seoTitle,
            },
            {
              name: "twitter:title",
              content:
                `${this.$whitelabel.branding.productName} - ` +
                this.$route.meta.seoTitle,
            },
            {
              property: "og:url",
              content:
                `${this.$whitelabel.branding.companyUrl}` + this.$route.path,
            },
            {
              name: "twitter:url",
              content:
                `${this.$whitelabel.branding.companyUrl}` + this.$route.path,
            },
            // {
            //   vmid: "description",
            //   name: "description",
            //   content: this.$route.meta.seoDescription || defaultDescription,
            // },
            // {
            //   vmid: "og:description",
            //   property: "og:description",
            //   content: this.$route.meta.seoDescription || defaultDescription,
            // },
            // {
            //   vmid: "twitter:description",
            //   name: "twitter:description",
            //   content: this.$route.meta.seoDescription || defaultDescription,
            // },
          ],
          link: [
            {
              vmid: "link-canonical",
              rel: "canonical",
              href:
                `${this.$whitelabel.branding.companyUrl}` + this.$route.path,
            },
          ],
        };
      },
    });
  },
};
export default Seo;
